import useCities from "./use-cities"
import reduce from "lodash/reduce"
import get from "lodash/get"
import mapValues from "lodash/mapValues"
import orderBy from "lodash/orderBy"
import chunk from "lodash/chunk"

const NUM_POPULATION_RANGES = 5

const useOverview = () => {
  const citiesData = useCities()
  const allCities = citiesData.filter(city => get(city, "slug") !== undefined)
  const totalCitiesCount = allCities.length

  const overviewData = reduce(allCities, (acc, city) => {
    const slug = get(city, "slug")
    const title = get(city, "summary.display_name") || get(city, "title")

    const cityPolicies = get(city, "section_policies.priority_policies") || []
    const cityPoliciesOther = get(city, "section_policies.priority_policies_other") || []
    const allCityPolicies = [...cityPolicies, ...cityPoliciesOther]
    allCityPolicies.forEach(policy => {
      if (policy === "") return
      if (acc.policies.all[policy]) {
        acc.policies.all[policy].push({ slug, title })
      } else {
        acc.policies.all[policy] = [{ slug, title }]
      }
    })

    const citySkills = get(city, "section_staff.skills") || []
    const citySkillsOther = get(city, "section_staff.skills_other") || []
    const allCitySkills = [...citySkills, ...citySkillsOther]
    allCitySkills.forEach(skill => {
      if (skill === "") return
      if (acc.skills.all[skill]) {
        acc.skills.all[skill].push({ slug, title })
      } else {
        acc.skills.all[skill] = [{ slug, title }]
      }
    })

    const allCityActivities = get(city, "section_activities.activities") || []
    allCityActivities.forEach(activity => {
      if (activity === "") return
      if (acc.activities.all[activity]) {
        acc.activities.all[activity].push({ slug, title })
      } else {
        acc.activities.all[activity] = [{ slug, title }]
      }
    })

    const experience = get(city, "section_activities.experience") || {}

    acc.activities.categories = acc.activities.categories.map(category => {
      const value = experience[category.id]
      return { ...category, average: category.average += value, [slug]: value }
    })
    
    const population = get(city, "summary.population")
    if (population) acc.population.all.push(population)

    return acc
  }, {
    total: totalCitiesCount,
    policies: { all: {} },
    skills: { all: {} },
    population: { all: [], chunks: [] },
    activities: {
      all: [],
      categories: [
        {
          id: "taking_risks", subject: '1', category: "Taking risks or testing new ideas", average: 0
        },
        {
          id: "data_driven", subject: '2', category: "Data-driven analytics/public data management", average: 0
        },
        {
          id: "engaging_residents", subject: '3', category: "Engaging residents in new ways", average: 0
        },
        {
          id: "digital_tech", subject: '4', category: "Developing new solutions based on digital technologies", average: 0
        },
        {
          id: "org_change", subject: '5', category: "Organizational change within the municipality", average: 0
        },
        {
          id: "human_centered", subject: '6', category: "Human-centered design", average: 0
        },
        {
          id: "rethinking", subject: '7', category: "Rethinking your city’s approach to financing partnerships", average: 0
        },
      ]
    },
    other_averages: {
      dedicated_funds: { yes: 0, no: 0, no_answer: 0 },
      explicit_strategy: { yes: 0, no: 0, no_answer: 0 },
      overall_approach: {
        specific: 0,
        holistic: 0,
        both: 0,
        no_answer: 0
      }
    }
  })

  overviewData.policies.all = mapValues(
    overviewData.policies.all,
    (cities, id) => ({
      id,
      title: id,
      cities,
      total: cities.length
    })
  )
  overviewData.policies.all = orderBy(overviewData.policies.all, ['total'], ['desc'])

  overviewData.skills.all = mapValues(
    overviewData.skills.all,
    (cities, id) => ({
      id,
      title: id,
      cities,
      total: cities.length
    })
  )
  overviewData.skills.all = orderBy(overviewData.skills.all, ['total'], ['desc'])

  overviewData.activities.all = mapValues(
    overviewData.activities.all,
    (cities, id) => ({
      id,
      title: id,
      cities,
      total: cities.length
    })
  )
  overviewData.skills.all = orderBy(overviewData.skills.all, ['total'], ['desc'])
  
  overviewData.activities.categories = overviewData.activities.categories.map(category => ({ 
    ...category,
    average: category.average / totalCitiesCount,
    cityCount: totalCitiesCount
  }))

  overviewData.population.all = overviewData.population.all.sort((a, b) => a - b )
  overviewData.population.chunks = chunk(overviewData.population.all, Math.round(totalCitiesCount / NUM_POPULATION_RANGES))

  const topSkills = overviewData.skills.all.slice(0, 5).map(data => {
    return {
      id: data.id,
      name: data.title,
      percent: Math.round((data.total / totalCitiesCount) * 100),
      total: data.total,
      totalCities: totalCitiesCount
    }
  })

  return { ...overviewData, topSkills }
}

export default useOverview
